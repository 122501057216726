import About from "../components/About";
import Atonomics from "../components/Atonomics";
import Header from "../components/Header";
import HowToBuy from "../components/HowToBuy";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <About />
      <HowToBuy />
      <Atonomics />
      <Footer />
    </div>
  );
}

export default Home;