import React from 'react';
import ImageSketches from '../components/ImageSketches';


const ForKids = () => {
  return (
    <ImageSketches />
  );
}

export default ForKids;