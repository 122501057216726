import React, { useState, useEffect } from 'react';
import buyBtn from '../assets/header/buyBtn.png';
import chartBtn from '../assets/header/chartBtn.png';

import headerBgMobile from '../assets/videos/header/headerBgMobile.webm';
import headerBgDesktop from '../assets/videos/header/headerBgDesktop.webm';
import centerText from '../assets/header/centerText.png';

import NavBar from './NavBar';

// icons
import coinMarketCap from '../assets/header/icons/coinMarketCap.png';
import eagle from '../assets/header/icons/eagle.png';
import dexScreener from '../assets/header/icons/dexScreener.png';
import tiktok from '../assets/header/icons/tiktok.png';
import telegram from '../assets/header/icons/telegram.png';
import ig from '../assets/header/icons/ig.png';
import x from '../assets/header/icons/x.png';
import coinGecko from '../assets/header/icons/coinGecko.png';
import youtube from '../assets/header/icons/youtube.png';

const Header = () => {
  const [backgroundVideo, setBackgroundVideo] = useState(headerBgMobile);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundVideo(headerBgDesktop);
      } else {
        setBackgroundVideo(headerBgMobile);
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div className="relative z-50">
      {/* Main Header Section with Background Video */}
      <div
        id="header"
        className="relative min-h-[175vh] md:min-h-[150vh] bg-cover bg-center h-screen flex flex-col justify-between items-center p-4 pb-20 z-50 -mb-48 sm:-mb-44 lg:-mb-52 2xl:-mb-60 3xl:-mb-96"
        style={{
          borderBottomLeftRadius: '50% 15%', borderBottomRightRadius: '50% 15%', overflow: 'hidden',
        }}
      >
        {/* Background Video */}
        <video
          src={backgroundVideo}
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover z-0"
          style={{ objectPosition: 'center top' }}
          type="video/webm"
        />

        <NavBar />

        <img src={centerText} alt="AVOCATO" className="absolute z-40 mt-52 lg:mt-20 xl:mt-32 2xl:mt-48 3xl:mt-60 3xl:h-80" />

        {/* <img src={centerText} alt="AVOCATO" className="z-40 -mt-[30rem] md:mt-0 lg:-mt-[30rem] xl:-mt-[30rem] 3xl:-mt-[50rem] 3xl:h-80" /> */}

        {/* Buttons Section */}
        <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 mb-20 md:mb-20 3xl:mb-24 z-50">
          <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
            <img src={buyBtn} alt="Buy $ATO!" className="h-20 3xl:h-32 duration-300 hover:scale-110" />
          </a>
          <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
            <img src={chartBtn} alt="Chart" className="h-20 3xl:h-32 duration-300 hover:scale-110" />
          </a>
        </div>
      </div>

      {/* Icon Container positioned inside the arc shape */}
      <div
        style={{
          background: 'linear-gradient(to right, #c0c1c3, #ececed)',
          border: '2px solid #abadb0',
        }}
        className="z-50 absolute left-0 right-0 mx-auto bottom-0 w-full max-w-2xl sm:max-w-3xl 3xl:max-w-4xl rounded-full p-3 flex justify-around items-center gap-1 sm:gap-4 3xl:gap-8"
      >
        <a
          href="https:/www.example.com/1"
          target="_blank"
          rel="noopener noreferrer"
          className="pointer-events-none w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={coinMarketCap} alt="coinMarketCap" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={eagle} alt="Eagle" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://www.dextools.io/app/en/solana/pair-explorer/C4ZuB3dbCEu7jQoSU3ikC5DKKqbXdttUVEBNcjCEsL7g?t=1729270951455"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={dexScreener} alt="dexScreener" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://www.tiktok.com/@avocato.solana?_t=8qShYm95BAW&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={tiktok} alt="TikTok" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://t.me/AvoCAToPortal"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={telegram} alt="Telegram" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://www.instagram.com/avocato.lol?igsh=MW02NDhxdXZ1YTBzYQ=="
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={ig} alt="Instagram" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://x.com/Avocato_solana"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={x} alt="X" className="h-full w-full object-contain" />
        </a>
        <a
          href="https://www.youtube.com/@AvocatoSol"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={youtube} alt="youtube" className="h-full w-full object-contain" />
        </a>

        <a
          href="https://www.coingecko.com/en/coins/avocato"
          target="_blank"
          rel="noopener noreferrer"
          className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center -mt-12 sm:-mt-16 3xl:-mt-20 duration-300 hover:scale-125"
        >
          <img src={coinGecko} alt="coinGecko" className="h-full w-full object-contain" />
        </a>
      </div>
    </div>
  );
};

export default Header;
