import React, { useState, useEffect } from 'react';

import bgMobile from '../assets/tokenomics/bgMobile.png';
import bgDesktop from '../assets/tokenomics/bgDesktop.png';

import header from '../assets/tokenomics/header.png';

import card1 from '../assets/tokenomics/card1.png';
import card2 from '../assets/tokenomics/card2.png';
import card3 from '../assets/tokenomics/card3.png';
import card4 from '../assets/tokenomics/card4.png';


const Atonomics = () => {
  const [backgroundImage, setBackgroundImage] = useState(bgDesktop);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)'); // lg breakpoint in Tailwind is 1024px

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundImage(bgDesktop);
      } else {
        setBackgroundImage(bgMobile);
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div
      id="atonomics"
      className="relative bg-cover bg-top bg-no-repeat min-h-screen flex flex-col justify-between items-center px-4 pt-40 md:pt-72 lg:pt-80 xl:pt-96 3xl:pt-[35rem] pb-[90vw] md:pb-[80vw] 3xl:pb-[70vw] z-20 -mb-32 sm:-mb-60 lg:-mb-96 3xl:-mb-[30rem]"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}
    >
      <div className="mb-16 3xl:mb-20">
        <img src={header} className="lg:w-[30rem] 3xl:w-[40rem]" alt="ATO-O-NOMICS" />
      </div>
      <div className="flex flex-col md:flex-row gap-12 mb-12 lg:mt-44 3xl:gap-20 3xl:mt-48">
        <div className="flex justify-center items-center">
          <img src={card1} alt="INITIAL SUPPLY" className="w-full 3xl:w-[30rem]" />
        </div>
        <div className="flex justify-center items-center">
          <img src={card2} alt="CIRC. SUPPLY 1B" className="w-full 3xl:w-[30rem]" />
        </div>
        <div className="flex justify-center items-center">
          <img src={card3} alt="LIQUIDITY BURNT" className="w-full 3xl:w-[30rem]" />
        </div>
        <div className="flex justify-center items-center">
          <img src={card4} alt="NETWORK SOLANA" className="w-full 3xl:w-[30rem]" />
        </div>
      </div>

      {/* <img src={atonomicsAvocatoDesk} alt="Avocato desk" className="mt-auto -mb-3" /> */}

    </div>
  );
};

export default Atonomics;
