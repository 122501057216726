import React, { useState, useEffect } from 'react';

import bgDesktop from '../assets/videos/footer/bgDesktop.webm';
import bgMobile from '../assets/videos/footer/bgMobile.webm';
import enterBtn from '../assets/footer/enterBtn.png';
import reserved from '../assets/footer/reserved.png';

const Footer = () => {
  const [backgroundVideo, setBackgroundVideo] = useState(bgDesktop);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)'); // lg breakpoint in Tailwind is 1024px

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundVideo(bgDesktop);
      } else {
        setBackgroundVideo(bgMobile);
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);

    // Add event listener
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div id="footer" className="relative bg-cover bg-top bg-no-repeat min-h-[150vh] flex flex-col justify-center items-center  z-0 py-32">
      {/* Background Video */}
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover z-0"
        style={{ objectPosition: 'center top' }} // Focus on the top part of the video
        type="video/webm"
      />

      {/* Content */}
      <div className="relative flex flex-col items-center space-y-20 z-10 mt-[45%] lg:mt-[60%] 3xl:mt-[65%]">
        <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
          <img src={enterBtn} alt="Enter the kennel" className="z-50 duration-300 hover:scale-110 3xl:w-[30rem]" />
        </a>
        <img src={reserved} alt="All MEOWS reserved" className="z-50 3xl:w-[15rem]" />
      </div>
      
    </div>
  );
}

export default Footer;
