import React, { useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import navbarLeft from '../assets/header/navbarLeft.png';
import navbarToggleMobile from '../assets/header/navbarToggleMobile.png';
import buyBtn from '../assets/header/buyBtn.png';

const NavBar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  const scrollToSection = (id) => {
    if (window.location.pathname !== '/') {
      navigate('/', { state: { scrollTo: id } });
    } else {
      const section = document.getElementById(id);
      if (section) {
        const yOffset = 150;
        const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
        setIsNavbarOpen(false);
        setIsAnimating(false);
      }
    }
  };

  const handleNavbarToggle = () => {
    if (isNavbarOpen) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsNavbarOpen(false);
        setIsAnimating(false);
      }, 700);
    } else {
      setIsNavbarOpen(true);
    }
  };

  return (
<>
    {/* Header Section */}
      <div className="flex justify-between lg:justify-center gap-4 w-full items-center relative">
        <img src={navbarLeft} alt="Avocato" className="h-20 3xl:h-32" />

        <div
          className="rounded-full hidden lg:block"
          style={{
            background: 'linear-gradient(to right, #c9cacc, #e4e5e6)',
            border: '2px solid #a8aeae',
          }}
        >
          <ul className="flex justify-between px-10 py-6 gap-2 xl:gap-4 3xl:px-12 3xl:py-8 3xl:gap-14">
            <li
              onClick={() => scrollToSection('header')}
              className="text-custom-brown font-bold text-lg xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
            >
              HOME
            </li>
            <li
              onClick={() => scrollToSection('about')}
              className="text-custom-brown font-bold text-lg xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
            >
              ABOUT
            </li>
            <li
              onClick={() => scrollToSection('howToBuy')}
              className="text-custom-brown font-bold text-lg xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
            >
              HOW TO BUY
            </li>
            <li
              onClick={() => scrollToSection('atonomics')}
              className="text-custom-brown font-bold text-lg xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
            >
              TOKENOMICS
            </li>
            <li>
              <Link
                to="/for-kids"
                className="text-custom-brown font-bold text-xl xl:text-2xl 3xl:text-5xl hover:text-green-400 cursor-pointer"
              >
                FOR KIDS
              </Link>
            </li>
          </ul>
        </div>

        {/* Navbar Icon - larger on 3xl */}
        <img
          src={navbarToggleMobile}
          alt="Navbar Icon"
          className="h-20 3xl:h-24 cursor-pointer lg:hidden ml-auto"
          onClick={handleNavbarToggle}
        />

        {/* Buy Button Image - larger on 3xl */}
        <a href="https://dexscreener.com/solana/c4zub3dbceu7jqosu3ikc5dkkqbxdttuvebncjcesl7g" target="_blank" rel="noopener noreferrer">
          <img
            src={buyBtn}
            alt="Buy $ATO!"
            className="lg:h-16 xl:h-20 3xl:h-28 hidden lg:block duration-300 hover:scale-110"
          />
        </a>
      </div>

      {/* Navbar Section */}
      {(isNavbarOpen || isAnimating) && (
        <div
          className={`absolute left-4 right-4 mt-4 bg-gradient-to-b from-[#bebfc1] to-[#efefef] border-[1px] border-[#adadad] rounded-lg shadow-lg p-8 flex flex-col items-center space-y-4 transition-transform duration-300 ease-in-out ${isNavbarOpen && !isAnimating ? 'navbar-slide-in' : 'navbar-slide-out'}`}
          style={{
            top: '100px',
            zIndex: 100,
          }}
        >
          <span
            onClick={() => scrollToSection('header')}
            className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
          >
            HOME
          </span>
          <div className="w-full border-t-[1px] border-[#acc7b8]" />
          <span
            onClick={() => scrollToSection('about')}
            className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
          >
            ABOUT
          </span>
          <div className="w-full border-t-[1px] border-[#acc7b8]" />
          <span
            onClick={() => scrollToSection('howToBuy')}
            className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
          >
            HOW TO BUY
          </span>
          <div className="w-full border-t-[1px] border-[#acc7b8]" />
          <span
            onClick={() => scrollToSection('atonomics')}
            className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
          >
            TOKENOMICS
          </span>
          <div className="w-full border-t-[1px] border-[#acc7b8]" />
          <Link
            to="/for-kids"
            className="text-brown-800 text-xl font-bold custom-font hover:text-green-600 cursor-pointer"
          >
            FOR KIDS
          </Link>
        </div>
      )}
    </>
  );
};

export default NavBar;