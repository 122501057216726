import React, { useState, useEffect, useRef } from 'react';
import Navbar from './NavBar';
import bgDesktop from '../assets/forKids/bgDesktop.png';
import bgMobile from '../assets/forKids/bgMobile.png';

import Slider from 'react-slick';
import downloadBtn from '../assets/forKids/downloadBtn.png';
import downloadAllBtn from '../assets/forKids/downloadAllBtn.png';

import header from '../assets/forKids/header.png';

import coloringPage1 from '../assets/forKids/coloringPage1.png';
import coloringPage2 from '../assets/forKids/coloringPage2.png';
import coloringPage3 from '../assets/forKids/coloringPage3.png';
import coloringPage4 from '../assets/forKids/coloringPage4.png';
import coloringPage5 from '../assets/forKids/coloringPage5.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import coloringSketchLadyAto from '../assets/pdf/coloring_sketch_lady_ATO.pdf';
import coloringSketchNinja from '../assets/pdf/coloring_sketch_ninja.pdf';
import coloringSketchSheriff from '../assets/pdf/coloring_sketch_sheriff.pdf';
import coloringSketchSmoothie from '../assets/pdf/coloring_sketch_smoothie.pdf';
import coloringSketchSpace from '../assets/pdf/coloring_sketch_space.pdf';
import coloringSketchAll from '../assets/pdf/coloring_sketch_all.pdf';

const ImageSketches = () => {
  const [backgroundImage, setBackgroundImage] = useState(bgDesktop);
  const ImageSketchesRef = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setBackgroundImage(bgDesktop);
      } else {
        setBackgroundImage(bgMobile);
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    { id: 1, image: coloringPage1, pdf: coloringSketchSmoothie, filename: 'coloring_sketch_smoothie.pdf' },
    { id: 2, image: coloringPage2, pdf: coloringSketchSpace, filename: 'coloring_sketch_space.pdf' },
    { id: 3, image: coloringPage3, pdf: coloringSketchNinja, filename: 'coloring_sketch_ninja.pdf' },
    { id: 4, image: coloringPage4, pdf: coloringSketchSheriff, filename: 'coloring_sketch_sheriff.pdf' },
    { id: 5, image: coloringPage5, pdf: coloringSketchLadyAto, filename: 'coloring_sketch_lady_ato.pdf' },
  ];

  return (
    <div
      id="image_sketches"
      ref={ImageSketchesRef}
      className="relative bg-cover bg-top bg-no-repeat min-h-screen flex flex-col items-center px-4 p-4"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center bottom',
        backgroundSize: 'cover',
      }}
    >
      <Navbar />

      <img src={header} className="w-96 mt-8" alt="COLORING PAGES" />


      <div className="flex justify-center items-start h-screen bg-transparent pt-8">
        <div className="w-[90%] max-w-[21rem] sm:max-w-[40rem] md:max-w-[50rem] lg:max-w-[60rem] xl:max-w-[80rem] 2xl:max-w-[90rem] bg-gray-500 bg-opacity-50 rounded-xl p-8 flex justify-center items-center overflow-visible relative">
          <Slider {...settings} className="w-full">
            {cards.map((card) => (
              <div key={card.id} className="relative flex justify-center items-center px-4 mb-8">
                <img src={card.image} alt={`Coloring Page ${card.id}`} className="w-full h-auto rounded-lg z-50" />
                <a href={card.pdf} download={card.filename} className="absolute -bottom-4 right-2 w-12 h-12 md:w-16 md:h-16 z-50">
                  <img src={downloadBtn} alt="Download" className="w-full h-full object-contain z-50" />
                </a>
              </div>
            ))}

          </Slider>
          <a href={coloringSketchAll} download="coloring_sketch_all.pdf" className="mt-8">
              <img src={downloadAllBtn} alt="Download All" className="absolute -bottom-6 left-1/2 md:left-auto transform md:translate-x-0 -translate-x-1/2 md:-right-12 w-64  " />
            </a>
        </div>
      </div>
    </div>
  );
}

export default ImageSketches;